@font-face {
  font-family: BellGothicBold;   /*Can be any text*/
  src: local(BellGothicBold), url("assets/fonts/BellGothicStd-Bold.otf") format("opentype");
}

@font-face {
  font-family: BellGothicLight;   /*Can be any text*/
  src: local(BellGothicLight), url("assets/fonts/BellGothicStd-Light.otf") format("opentype");
}

@font-face {
  font-family: BellGothicBlack;   /*Can be any text*/
  src: local(BellGothicBlack), url("assets/fonts/BellGothicStd-Black.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ps__rail-x, .ps__rail-y, .ps__thumb-y {
  z-index: 1500;
}
